<template>
  <v-data-table
    :headers="$vuetify.breakpoint.xs ? headersXs : headers"
    :items="items"
    :server-items-length="total"
    :options.sync="options"
    :loading="loading"
    class="elevation-1 align-self-start col-12 pa-0 items-data-table"
    item-key="id"
  >
    <template v-slot:top>
      <v-toolbar flat dense>
        <v-toolbar-title>Pedidos</v-toolbar-title>

        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>

        <v-spacer></v-spacer>

        <v-btn
          icon
          color="primary"
          :disabled="!items.length"
          :loading="loadingExportToExcel"
          @click="exportToExcel()"
          title="Exportar a excel"
          aria-label="Exportar a exce"
        >
          <v-icon>mdi-microsoft-excel</v-icon>
        </v-btn>
      </v-toolbar>

      <v-form ref="filterForm">
        <v-container fluid class="pt-0">
          <v-row
            align="start"
            justify="start"
            :no-gutters="$vuetify.breakpoint.xs"
          >
            <v-col cols="12" md="6" class="pt-0">
              <v-autocomplete
                v-model="cliente"
                :loading="loadingClientes"
                :items="clientes"
                :search-input.sync="clientesQuery"
                single-line
                hide-details
                cache-items
                clearable
                label="Cliente"
                placeholder="Buscar cliente"
                item-text="RazonSocial"
                return-object
                @input="setCliente"
                :no-data-text="noDataText"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="3" class="pt-0">
              <v-menu
                v-model="menuDesde"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="computedDesdeFormatted"
                    label="Desde"
                    prepend-icon="mdi-calendar"
                    single-line
                    hide-details
                    clearable
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="filters.desde" @input="menuDesde = false"></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="3" class="pt-0">
              <v-menu
                v-model="menuHasta"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="computedHastaFormatted"
                    label="Hasta"
                    prepend-icon="mdi-calendar"
                    single-line
                    hide-details
                    clearable
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="filters.hasta" @input="menuHasta = false"></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </template>
    <template v-slot:item.action="{ item }">
      <v-icon
        color="info"
        @click="openDetalle(item)"
        aria-label="Detalle"
        title="Detalle"
      >
        mdi-eye-settings
      </v-icon>
    </template>
    <template v-slot:item.Importe="{ item }">
      <span class="text-no-wrap">
        {{ numberFormat(item.Importe, { style: 'currency', currency: 'ARS' }) }}
      </span>
    </template>
    <template v-slot:item.Estado="{ item }">
      <v-chip :color="getEstadoColor(item.Estado)" text-color="white" small>
        {{ item.Estado }}
      </v-chip>
    </template>
    <template v-slot:item.Fecha="{ item }">
      <template v-if="$vuetify.breakpoint.xs">
        <v-container fluid class="pa-0">
          <v-row no-gutters>
            <v-col cols="6" class="text-left font-weight-bold">
              <v-chip :color="getEstadoColor(item.Estado)" text-color="white" small>
                {{ item.Estado }}
              </v-chip>
            </v-col>
            <v-col cols="6">
              <span class="text-no-wrap">
                {{ item.Fecha }}
              </span>
            </v-col>
          </v-row>
        </v-container>
      </template>
      <template v-else>
        <span class="text-no-wrap">
          {{ item.Fecha }}
        </span>
      </template>
    </template>
  </v-data-table>
</template>

<script>

import _ from 'lodash'
import moment from 'moment'

export default {
  data: () => ({
    loading: false,
    dialog: false,
    loadingClientes: false,
    loadingExportToExcel: false,
    clientes: [],
    cliente: null,
    idCliente: -1,
    clientesQuery: '',
    menuDesde: false,
    menuHasta: false,
    filters: {
      desde: new Date().toISOString().substr(0, 10),
      hasta: new Date().toISOString().substr(0, 10),
      pagina_solicitada: 1,
      registrosPorPagina: 10
    },
    search: '',
    headers: [
      { text: 'Fecha', value: 'Fecha', sortable: false },
      { text: 'Cliente', value: 'Razon Social', sortable: false },
      { text: 'Estado', value: 'Estado', align: 'center', sortable: false },
      { text: 'Importe Total', value: 'Importe', align:'right', sortable: false },
      { text: 'Acciones', value: 'action', align: 'center', sortable: false },
    ],
    headersXs: [
      { text: '', value: 'Fecha', align: 'center', sortable: false },
      { text: 'Cliente', value: 'Razon Social', sortable: false },
      { text: 'Importe Total', value: 'Importe', align:'right', sortable: false },
      { text: 'Acciones', value: 'action', align: 'center', sortable: false },
    ],
    items: [],
    total: 0,
    options: {},
    item: {},
    noDataText: 'Escriba para buscar...',
  }),

  computed: {
    computedDesdeFormatted: {
      get: function () {
        if (!this.filters.desde) return null

        const [year, month, day] = this.filters.desde.split('-')

        return `${day}/${month}/${year}`
      },
      set: function (newValue) {
        this.filters.desde = newValue
      }
    },
    computedHastaFormatted: {
      get: function () {
        if (!this.filters.hasta) return null

        const [year, month, day] = this.filters.hasta.split('-')

        return `${day}/${month}/${year}`
      },
      set: function (newValue) {
        this.filters.hasta = newValue
      }
    },
  },

  watch: {
    options: {
      handler () {
        this.query()
      },
      deep: true,
    },

    clientesQuery (val) {
      if (!this.cliente && val) {
        this.noDataText = 'Buscando...'
        this.debounceClientes(val)
      }
    },

    'idCliente': function () {
      this.refresh()
    },

    'filters.desde': function () {
      this.refresh()
    },

    'filters.hasta': function () {
      this.refresh()
    },
  },

  methods: {
    debounceClientes: _.debounce(function (val) {
      this.queryClientes(val)
    }, 1000),

    queryClientes (val) {
      this.loadingClientes = true;

      this.$soapClient.call('listarClientes', {
        filtro: {
          consulta: val,
        },
      })
      .then((response) => {
        this.clientes = response.data.resultado;

        if (!this.clientes.length) {
          this.noDataText = 'No hay datos disponibles'
        }
      })
      .catch((error) => {
        if (!!error.code && (error.code === "400")) {
          this.$eventHub.$emit('snackbar-message', error.message, 'error')
        } else {
          this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud', 'error')
        }
      })
      .then(() => {
        this.loadingClientes = false;
      })
    },

    setCliente () {
      if (!this.cliente) {
        this.idCliente = -1
      } else {
        this.idCliente = this.cliente.Id
      }

      const parsedCliente = JSON.stringify(this.cliente);
      sessionStorage.setItem('pedidosCliente', parsedCliente);
    },

    refresh: function () {
      this.options.page = 1
      this.query()
    },

    query: function () {
      this.loading = true

      this.filters.pagina_solicitada = this.options.page
      this.filters.registrosPorPagina = this.options.itemsPerPage

      this.$soapClient.call('listarPedidos', {
        idCliente: this.idCliente,
        filtro: this.filters,
      })
      .then((response) => {
        this.items = response.data.resultado
        this.total = this.items.lenght

        const parsedFilters = JSON.stringify(this.filters);
        sessionStorage.setItem('pedidosFilters', parsedFilters);
      })
      .catch((error) => {
        if (!!error.code && (error.code === "400")) {
          this.$eventHub.$emit('snackbar-message', error.message, 'error')
        } else {
          this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud', 'error')
        }
      })
      .then(() => {
        this.loading = false
      })
    },

    openDetalle (item) {
      this.$router.push({
        name: 'pedidos/detalle',
        params: {
          id: item.id,
        }
      })
    },

    numberFormat(value, options) {
      const numberFormat = new Intl.NumberFormat('es-AR', options)

      return numberFormat.format(value)
    },

    getEstadoColor(estado) {
      const estadoUC = estado ? estado.toUpperCase() : ''
      let color

      switch (estadoUC) {
        case 'FACTURADO':
          color = 'green'
          break
        case 'PENDIENTE':
          color = 'blue'
          break
        case 'CANCELADO':
          color = 'red'
          break
        default:
          color = 'black'
      }

      return color
    },

    exportToExcel() {
      this.loadingExportToExcel = true 

      const ExcelJS = require('exceljs');
      
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Pedidos');

      const titleRow = worksheet.addRow(['PLANILLA DE PEDIDOS - ' + sessionStorage.getItem('razonSocial')]);
      worksheet.mergeCells(`A1:F1`);

      titleRow.alignment = {
          horizontal: 'center'
      };

      worksheet.addRow(
        ['FECHA', 
         'CLIENTE', 
         'CUIT/DOC', 
         'ESTADO',
         'IMPORTE TOTAL',
         'COMENTARIO', 
        ]
      );

      this.items.forEach(row => {
        worksheet.addRow([
          moment(row['Fecha'], 'DD/MM/YYYY').locale('es').format('DD/MM/YYYY'),
          row['Razon Social'],
          row['CuitDoc'],
          row['Estado'],
          row['Importe'],
          row['Comentario']
        ]);
      });

      worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
        if (rowNumber == 1) {
          row.height = 30;
        } else {
          row.height = 58;
        }

        row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
          };

          cell.alignment = {
            horizontal: 'center',
            vertical: 'middle',  
            wrapText: true       
          };

          if (rowNumber === 1) {
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: '00b0f0' }
            };

            cell.font = {
              color: { argb: 'ffffff' },
              bold: true,
              underline: true 
            };
          }

          if (rowNumber === 2) {
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'FFFFFF' }
            };

            cell.font = {
              bold: true
            };
          }

          if (rowNumber > 2) {
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'd9e2f3' }
            };

            if (colNumber === 5) {
              cell.alignment = {
                horizontal: 'right',
                vertical: 'middle',  
                wrapText: true       
              };

              cell.numFmt = '"$"#,##0.00_)'

              cell.font = {
                color: { argb: '5280c9' },
                bold: true
              };
            }
          }
        });
      });

      worksheet.columns.forEach((column) => {
          column.width = 15;
      });

      worksheet.getColumn(2).width = 35
      worksheet.getColumn(6).width = 35

      const d = new Date();
      const fileName = `Planilla de pedidos-${sessionStorage.getItem('razonSocial')}-${d.getDate()}_${d.getMonth() + 1}.xlsx`;

      setTimeout(() => {
        workbook.xlsx.writeBuffer().then(buffer => {
          const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = fileName;
          document.body.appendChild(a);
          a.click();

          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);

          this.loadingExportToExcel = false
        });
      }, 0)
    },
  },

  mounted() {
    if (sessionStorage.getItem('pedidosFilters')) {
      try {
        this.filters = JSON.parse(sessionStorage.getItem('pedidosFilters'));
      } catch(e) {
        sessionStorage.removeItem('pedidosFilters');
      }
    }

    if (sessionStorage.getItem('pedidosCliente')) {
      try {
        this.cliente = JSON.parse(sessionStorage.getItem('pedidosCliente'));

        if (!this.cliente) {
          this.idCliente = -1
        } else {
          this.idCliente = this.cliente.Id
          this.queryClientes(this.cliente.RazonSocial)
        }
      } catch(e) {
        sessionStorage.removeItem('pedidosCliente');
      }
    }
  },
};
</script>

<style scoped>
  >>> .v-data-table-header-mobile {
    display: none;
  }

  >>> table > tbody > tr > td:nth-child(1) > div.v-data-table__mobile-row__header {
    display: none;
  }

  >>> table > tbody > tr > td:nth-child(1) > div.v-data-table__mobile-row__cell {
    width: 100%;
  }
</style>
