var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1 align-self-start col-12 pa-0 items-data-table",attrs:{"headers":_vm.$vuetify.breakpoint.xs ? _vm.headersXs : _vm.headers,"items":_vm.items,"server-items-length":_vm.total,"options":_vm.options,"loading":_vm.loading,"item-key":"id"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","dense":""}},[_c('v-toolbar-title',[_vm._v("Pedidos")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","color":"primary","disabled":!_vm.items.length,"loading":_vm.loadingExportToExcel,"title":"Exportar a excel","aria-label":"Exportar a exce"},on:{"click":function($event){return _vm.exportToExcel()}}},[_c('v-icon',[_vm._v("mdi-microsoft-excel")])],1)],1),_c('v-form',{ref:"filterForm"},[_c('v-container',{staticClass:"pt-0",attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"start","justify":"start","no-gutters":_vm.$vuetify.breakpoint.xs}},[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"6"}},[_c('v-autocomplete',{attrs:{"loading":_vm.loadingClientes,"items":_vm.clientes,"search-input":_vm.clientesQuery,"single-line":"","hide-details":"","cache-items":"","clearable":"","label":"Cliente","placeholder":"Buscar cliente","item-text":"RazonSocial","return-object":"","no-data-text":_vm.noDataText},on:{"update:searchInput":function($event){_vm.clientesQuery=$event},"update:search-input":function($event){_vm.clientesQuery=$event},"input":_vm.setCliente},model:{value:(_vm.cliente),callback:function ($$v) {_vm.cliente=$$v},expression:"cliente"}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Desde","prepend-icon":"mdi-calendar","single-line":"","hide-details":"","clearable":"","readonly":""},model:{value:(_vm.computedDesdeFormatted),callback:function ($$v) {_vm.computedDesdeFormatted=$$v},expression:"computedDesdeFormatted"}},on))]}}]),model:{value:(_vm.menuDesde),callback:function ($$v) {_vm.menuDesde=$$v},expression:"menuDesde"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menuDesde = false}},model:{value:(_vm.filters.desde),callback:function ($$v) {_vm.$set(_vm.filters, "desde", $$v)},expression:"filters.desde"}})],1)],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Hasta","prepend-icon":"mdi-calendar","single-line":"","hide-details":"","clearable":"","readonly":""},model:{value:(_vm.computedHastaFormatted),callback:function ($$v) {_vm.computedHastaFormatted=$$v},expression:"computedHastaFormatted"}},on))]}}]),model:{value:(_vm.menuHasta),callback:function ($$v) {_vm.menuHasta=$$v},expression:"menuHasta"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menuHasta = false}},model:{value:(_vm.filters.hasta),callback:function ($$v) {_vm.$set(_vm.filters, "hasta", $$v)},expression:"filters.hasta"}})],1)],1)],1)],1)],1)]},proxy:true},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":"info","aria-label":"Detalle","title":"Detalle"},on:{"click":function($event){return _vm.openDetalle(item)}}},[_vm._v(" mdi-eye-settings ")])]}},{key:"item.Importe",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(_vm.numberFormat(item.Importe, { style: 'currency', currency: 'ARS' }))+" ")])]}},{key:"item.Estado",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getEstadoColor(item.Estado),"text-color":"white","small":""}},[_vm._v(" "+_vm._s(item.Estado)+" ")])]}},{key:"item.Fecha",fn:function(ref){
var item = ref.item;
return [(_vm.$vuetify.breakpoint.xs)?[_c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"text-left font-weight-bold",attrs:{"cols":"6"}},[_c('v-chip',{attrs:{"color":_vm.getEstadoColor(item.Estado),"text-color":"white","small":""}},[_vm._v(" "+_vm._s(item.Estado)+" ")])],1),_c('v-col',{attrs:{"cols":"6"}},[_c('span',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(item.Fecha)+" ")])])],1)],1)]:[_c('span',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(item.Fecha)+" ")])]]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }