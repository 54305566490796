<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :loading="loading"
    disable-pagination
    hide-default-footer
    class="elevation-1 align-self-start col-12 pa-0"
    item-key="id"
  >
    <template v-slot:top>
      <v-card flat>
        <v-card-subtitle class="pb-0">
          Pedido Nº {{ id }}
        </v-card-subtitle>
        <v-card-title class="py-0">
          {{ tapa['Razon Social'] }}
        </v-card-title>
        <v-card-text>
          Fecha: {{ tapa.Fecha }}
        </v-card-text>
      </v-card>
    </template>
    <template v-slot:item.precio="{ item }">
        {{ numberFormat(item.precio, { style: 'currency', currency: 'ARS' }) }}
    </template>
    <template v-slot:item.subtotal="{ item }">
        {{ numberFormat(item.subtotal, { style: 'currency', currency: 'ARS' }) }}
    </template>
    <template v-slot:item.bonificacion="{ item }">
        {{ numberFormat(item.bonificacion) }} %
    </template>


    <template v-slot:body.append="{ headers }">
      <tr>
        <th :colspan="headers.length -1" class="text-right">
          Total
        </th>
        <td class="text-right">
          {{ numberFormat(tapa.Importe, { style: 'currency', currency: 'ARS' }) }}
        </td>
      </tr>
    </template>

    <template v-slot:footer>
      <v-card v-if="tapa.Comentario.length > 0" flat>
        <v-card-text>
          <div class="caption">Comentario:</div>
          <div style="white-space: pre-wrap;">{{ tapa.Comentario }}</div>
        </v-card-text>
      </v-card>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          :to="{ name: 'pedidos' }"
          color="secondary"
          dark
        >
          Volver
        </v-btn>

        <v-spacer></v-spacer>
      </v-card-actions>
    </template>
  </v-data-table>
</template>

<script>

export default {
  data: () => ({
    loading: true,
    id: null,
    tapa: {
      'Razon Social': '',
      Fecha: '',
      Comentario: '',
    },
    headers: [
      { text: 'Código', value: 'codigo', sortable: false },
      { text: 'Detalle', value: 'detalle', sortable: false },
      { text: 'Cantidad', value: 'cantidad', sortable: false },
      { text: 'Precio', value: 'precio', align: 'right', sortable: false },
      { text: 'Bonificación', value: 'bonificacion', align: 'right', sortable: false },
      { text: 'Subtotal', value: 'subtotal', align: 'right', sortable: false },
    ],
    items: [],
    item: {},
  }),

  mounted() {
    this.id = this.$route.params.id
    this.query()
  },

  methods: {
    query: function () {
      this.loading = true

      this.$soapClient.call('listarPedidoCuerpo', {
        idTapa: this.id,
      })
      .then((response) => {
        this.tapa = response.data.resultado.tapa
        this.items = response.data.resultado.items
      })
      .catch((error) => {
        if (!!error.code && (error.code === "400")) {
          this.$eventHub.$emit('snackbar-message', error.message, 'error')
        } else {
          this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud', 'error')
        }
      })
      .then(() => {
        this.loading = false
      })
    },

    numberFormat(value, options) {
      const numberFormat = new Intl.NumberFormat('es-AR', options)

      return numberFormat.format(value)
    },
  }
}

</script>

<style>
  >>> .v-data-table-header-mobile {
    display: none;
  }
</style>
