var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1 align-self-start col-12 pa-0",attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"disable-pagination":"","hide-default-footer":"","item-key":"id"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-card',{attrs:{"flat":""}},[_c('v-card-subtitle',{staticClass:"pb-0"},[_vm._v(" Pedido Nº "+_vm._s(_vm.id)+" ")]),_c('v-card-title',{staticClass:"py-0"},[_vm._v(" "+_vm._s(_vm.tapa['Razon Social'])+" ")]),_c('v-card-text',[_vm._v(" Fecha: "+_vm._s(_vm.tapa.Fecha)+" ")])],1)]},proxy:true},{key:"item.precio",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.numberFormat(item.precio, { style: 'currency', currency: 'ARS' }))+" ")]}},{key:"item.subtotal",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.numberFormat(item.subtotal, { style: 'currency', currency: 'ARS' }))+" ")]}},{key:"item.bonificacion",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.numberFormat(item.bonificacion))+" % ")]}},{key:"body.append",fn:function(ref){
var headers = ref.headers;
return [_c('tr',[_c('th',{staticClass:"text-right",attrs:{"colspan":headers.length -1}},[_vm._v(" Total ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.numberFormat(_vm.tapa.Importe, { style: 'currency', currency: 'ARS' }))+" ")])])]}},{key:"footer",fn:function(){return [(_vm.tapa.Comentario.length > 0)?_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('div',{staticClass:"caption"},[_vm._v("Comentario:")]),_c('div',{staticStyle:{"white-space":"pre-wrap"}},[_vm._v(_vm._s(_vm.tapa.Comentario))])])],1):_vm._e(),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"to":{ name: 'pedidos' },"color":"secondary","dark":""}},[_vm._v(" Volver ")]),_c('v-spacer')],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }