<template>
  <v-container class="align-self-start">
    <ValidationObserver ref="obs" v-slot="{ invalid, validated, passes }"
      class="align-self-start col-12 pa-0">
      <v-form @submit.prevent="passes(save)">
        <v-container>
          <ValidationProvider name="Cliente" rules="required" v-slot="{ errors, valid }">
            <v-autocomplete
              autofocus
              filled
              v-model="cliente"
              :loading="loadingClientes"
              :items="clientes"
              :search-input.sync="clientesQuery"
              cache-items
              clearable
              required
              label="Cliente"
              placeholder="Buscar cliente"
              item-text="RazonSocial"
              return-object
              :error-messages="errors"
              :success="valid"
              @input="setCliente"
            ></v-autocomplete>
          </ValidationProvider>

          <ValidationProvider name="Items" rules="required" v-slot="{ errors, valid }">
            <v-data-table
              v-model="items"
              :headers="$vuetify.breakpoint.xs ? headersXs : headers"
              :items="items"
              class="elevation-1 align-self-start col-12 pa-0 mb-4 items-data-table"
              item-key="id"
              disable-pagination
              hide-default-footer
            >
              <template v-slot:top>
                <v-toolbar flat color="white">
                  <v-toolbar-title>Items</v-toolbar-title>

                  <v-divider
                    class="mx-4"
                    inset
                    vertical
                  ></v-divider>

                  <v-spacer></v-spacer>

                  <v-btn small color="primary" dark @click="openNewItem()">Nuevo</v-btn>
                </v-toolbar>
              </template>
              <template v-slot:item.precio="{ item }">
                <span class="text-no-wrap">
                  {{ numberFormat(item.precio, moneyFormatOptions) }}
                </span>
              </template>
              <template v-slot:item.subtotal="{ item }">
                <span class="text-no-wrap">
                  {{ numberFormat(item.subtotal, moneyFormatOptions) }}
                </span>
              </template>
              <template v-slot:item.bonificacion="{ item }">
                <span class="text-no-wrap">{{ item.bonificacion }} %</span>
              </template>
              <template v-slot:item.action="{ item }">
                <template v-if="$vuetify.breakpoint.xs">
                  <v-container fluid class="pa-0">
                    <v-row no-gutters>
                      <v-col cols="6" class="text-left font-weight-bold">
                        Cod. {{ item.codigo }}
                      </v-col>
                      <v-col cols="6">
                        <span class="text-no-wrap">
                          <v-icon
                            color="primary"
                            class="ml-2"
                            @click="openEditItem(item)"
                            aria-label="Editar"
                            title="Editar"
                          >
                            mdi-pencil
                          </v-icon>

                          <v-icon
                            color="error"
                            class="ml-2"
                            @click="removeItem(item)"
                            aria-label="Quitar"
                            title="Quitar"
                          >
                            mdi-delete
                          </v-icon>
                        </span>
                      </v-col>
                    </v-row>
                  </v-container>
                </template>
                <template v-else>
                  <span class="text-no-wrap">
                    <v-icon
                      color="primary"
                      class="ml-2"
                      @click="openEditItem(item)"
                      aria-label="Editar"
                      title="Editar"
                    >
                      mdi-pencil
                    </v-icon>

                    <v-icon
                      color="error"
                      class="ml-2"
                      @click="removeItem(item)"
                      aria-label="Quitar"
                      title="Quitar"
                    >
                      mdi-delete
                    </v-icon>
                  </span>
                </template>
              </template>
            </v-data-table>

            <template v-if="!valid">
              <template v-for="(error, key) in errors">
                <div :key="key" class="caption error--text my-2">{{ error }}</div>
              </template>
            </template>
          </ValidationProvider>

          <ValidationProvider
            v-if="$vuetify.breakpoint.smAndUp"
            name="Comentario"
            rules=""
            v-slot="{ errors, valid }"
          >
            <v-textarea
              v-model="datosTapa.comentario"
              label="Comentario"
              :error-messages="errors"
              :success="valid"
            ></v-textarea>
          </ValidationProvider>

          <v-row
            class="mt-4"
            :class="{ 'text-right': $vuetify.breakpoint.smAndUp }"
            :justify="$vuetify.breakpoint.smAndUp ? 'end' : 'space-between'"
          >
            <v-col cols="auto">
              <span class="caption">{{ $vuetify.breakpoint.smAndUp ? 'Descuento' : 'Desc' }}</span>: <span class="text-no-wrap orange--text">{{ numberFormat(datosTapa.descuento, moneyFormatOptions) }}</span>
            </v-col>
            <v-col cols="auto" :class="{ 'text-right': $vuetify.breakpoint.xs }">
              <span class="caption">{{ $vuetify.breakpoint.smAndUp ? 'Importe Total' : 'Total' }}</span>: <span class="text-no-wrap green--text font-weight-bold">{{ numberFormat(datosTapa.importeTotal, moneyFormatOptions) }}</span>
            </v-col>
          </v-row>

          <div v-if="$vuetify.breakpoint.xsOnly" class="mt-4">
            <v-btn
              color="secondary"
              block
              dark
              @click="addComment"
            >
              Agregar comentario
            </v-btn>
          </div>

          <div class="mt-4 text-right">
            <v-btn
              type="submit"
              color="primary"
              :block="$vuetify.breakpoint.xsOnly"
              dark
              :loading="saving"
              @click.prevent="passes(save)"
            >
              Guardar
            </v-btn>
          </div>
        </v-container>
      </v-form>
    </ValidationObserver>

    <v-dialog v-model="itemFormDialog" max-width="500px">
      <ValidationObserver ref="item" v-slot="{ passes }">
        <v-form @submit.prevent="passes(saveItem)">
          <v-card>
            <v-card-title>
              <span class="headline">
                {{ formTitle }}
              </span>
            </v-card-title>

            <v-divider></v-divider>

            <v-card-text>
              <ValidationProvider
                name="Código"
                rules="required"
                v-slot="{ errors, valid }"
                class="mb-4"
              >
                <v-text-field
                  v-model="item.codigo"
                  label="Código"
                  :error-messages="errors"
                  :success="valid"
                  required
                  @change="searchArticulo"
                ></v-text-field>
              </ValidationProvider>

              <ValidationProvider
                name="Detalle"
                rules="required"
                v-slot="{ errors, valid }"
                class="mb-4"
              >
                <v-text-field
                  v-model="item.detalle"
                  label="Detalle"
                  :error-messages="errors"
                  :success="valid"
                  required
                ></v-text-field>
              </ValidationProvider>

              <ValidationProvider
                name="Cantidad"
                rules="required|min_value:1|integer"
                v-slot="{ errors, valid }"
                class="mb-4"
              >
                <v-text-field
                  type="number"
                  step="1"
                  min="1"
                  v-model.number="item.cantidad"
                  label="Cantidad"
                  :error-messages="errors"
                  :success="valid"
                  required
                ></v-text-field>
              </ValidationProvider>

              <ValidationProvider
                name="Precio"
                rules="required|min_value:0.01"
                v-slot="{ errors, valid }"
                class="mb-4"
              >
                <v-text-field
                  type="number"
                  step="0.01"
                  min="0.01"
                  v-model.number="item.precio"
                  label="Precio"
                  :error-messages="errors"
                  :success="valid"
                  required
                ></v-text-field>
              </ValidationProvider>

              <ValidationProvider
                name="Bonificación"
                rules="min_value:0"
                v-slot="{ errors, valid }"
              >
                <v-text-field
                  type="number"
                  step="0.01"
                  v-model.number="item.bonificacion"
                  label="Bonificación"
                  append-icon="mdi-percent"
                  :error-messages="errors"
                  :success="valid"
                ></v-text-field>
              </ValidationProvider>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                type="submit"
                color="primary"
                text
              >
                Guardar
              </v-btn>

              <v-btn text @click="closeItemForm">Cerrar</v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </ValidationObserver>
    </v-dialog>

    <v-dialog v-model="commentDialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">
            Agregar Comentario
          </span>
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text>
          <v-textarea
            v-model="datosTapa.comentario"
            label="Comentario"
          ></v-textarea>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text @click="closeCommentDialog">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="saldoPendienteDialog" max-width="600">
      <v-card>
        <v-toolbar color="warning" dark flat>
          AVISO
        </v-toolbar>

        <v-card-text class="text-h6 pa-12">
          El cliente {{ clienteCtaCte.RazonSocial }} tiene un saldo pendiente de {{ numberFormat(clienteSaldo, moneyFormatOptions) }}.
        </v-card-text>

        <v-card-actions class="justify-end">
          <v-btn
            color="primary"
            text
            @click="dialogCtaCte = true"
          >
            VER CTA CTE
          </v-btn>

          <v-btn text @click="saldoPendienteDialog = false">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogCtaCte" scrollable fullscreen>
      <CtaCteList
        :active="dialogCtaCte"
        :cliente="clienteCtaCte"
        @dismiss="dialogCtaCte = false"
      />
    </v-dialog>
  </v-container>
</template>

<script>

import _ from 'lodash'
import {
  ValidationObserver,
  ValidationProvider
} from "vee-validate";
import CtaCteList from '../../components/CtaCteList'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    CtaCteList,
  },

  data: () => ({
    loading: false,
    saving: false,
    searchingArticulo: false,
    loadingClientes: false,
    clientes: [],
    cliente: null,
    clientesQuery: '',
    clienteSaldo: 0,
    clienteCtaCte: {},
    datosTapa: {
      fecha: null,
      idCliente: null,
      razonSocialCliente: null,
      descuento: 0,
      importeTotal: 0,
      comentario: null,
    },
    headers: [
      { text: 'Item', value: 'item', align: 'left', sortable: false },
      { text: 'Código', value: 'codigo', sortable: false },
      { text: 'Detalle', value: 'detalle', sortable: false },
      { text: 'Cantidad', value: 'cantidad', align: 'right', sortable: false },
      { text: 'Precio', value: 'precio', align: 'right', sortable: false },
      { text: 'Bonificación', value: 'bonificacion', align: 'right', sortable: false },
      { text: 'Subtotal', value: 'subtotal', align: 'right', sortable: false },
      { text: 'Acciones', value: 'action', align: 'right', sortable: false },
    ],
    headersXs: [
      { text: '', value: 'action', sortable: false },
      { text: 'Detalle', value: 'detalle', sortable: false },
      { text: 'Cantidad', value: 'cantidad', align: 'right', sortable: false },
      { text: 'Precio', value: 'precio', align: 'right', sortable: false },
      { text: 'Bonificación', value: 'bonificacion', align: 'right', sortable: false },
      { text: 'Subtotal', value: 'subtotal', align: 'right', sortable: false },
    ],
    items: [],
    itemIndex: -1,
    itemCounter: 0,
    item: {
      item: null,
      codigo: '',
      detalle: '',
      cantidad: 1,
      precio: 0,
      bonificacion: 0,
      subtotal: 0
    },
    defaultItem: {
      item: null,
      codigo: '',
      detalle: '',
      cantidad: 1,
      precio: 0,
      bonificacion: 0,
      subtotal: 0
    },
    itemFormDialog: false,
    commentDialog: false,
    saldoPendienteDialog: false,
    dialogCtaCte: false,
    filtroArticulo: {
      consulta: null,
      pagina_solicitada: 1,
      registrosPorPagina: 1,
    },
    moneyFormatOptions: {
      style: 'currency',
      currency: 'ARS',
    },
  }),

  computed: {
    formTitle: {
      get() {
        return (this.itemIndex > -1) ? 'Editar item' : 'Nuevo item'
      },
    },
  },

  watch: {
    clientesQuery (val) {
      this.debounceClientes(val)
    },
  },

  methods: {
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')

      return `${day}/${month}/${year}`
    },

    debounceClientes: _.debounce(function (val) {
      this.queryClientes(val)
    }, 1000),

    queryClientes (val) {
      this.loadingClientes = true;

      this.$soapClient.call('listarClientes', {
        filtro: {
          consulta: val,
          // pagina_solicitada: 1
          // registrosPorPagina: 100
        },
      })
      .then((response) => {
        this.clientes = response.data.resultado;
      })
      .catch((error) => {
        if (!!error.code && (error.code === "400")) {
          this.$eventHub.$emit('snackbar-message', error.message, 'error')
        } else {
          this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud', 'error')
        }
      })
      .then(() => {
        this.loadingClientes = false;
      })
    },

    save () {
      this.saving = true

      this.datosTapa['_xsi:type'] = 'ns2:TapaPedido'
      this.datosTapa.fecha = (new Date()).toISOString().substr(0, 10)

      var items = []
      var itemsAux = {}

      var itemsCount = this.items.length

      for (var i = 0; i < itemsCount; i++) {
        var item = this.items[i]

        itemsAux['item'+(i + 1)] = {
          '_xsi:type': 'ns2:CuerpoPedido',
          item: item.item,
          codigo: item.codigo,
          detalle: item.detalle,
          cantidad: item.cantidad,
          precio: item.precio,
          bonificacion: item.bonificacion,
          subtotal: item.subtotal,
        }
      }

      items.push(itemsAux)

      this.$soapClient.call('crearPedido', {
        datosTapa: this.datosTapa,
        items: items,
      })
      .then(() => {
        this.checkSaldoPendiente(this.cliente)
        this.resetForm()

        this.$eventHub.$emit('snackbar-message', 'Los datos fueron guardados con éxito', 'success')
      })
      .catch((error) => {
        if (!!error.code && (error.code === "400")) {
          this.$eventHub.$emit('snackbar-message', error.message, 'error')
        } else {
          this.$eventHub.$emit('snackbar-message', 'Disculpe, no se pudo completar su solicitud', 'error')
        }
      })
      .then(() => {
        this.saving = false
      })
    },

    setCliente () {
      this.clienteSaldo = 0

      if (this.cliente && this.cliente?.Id) {
        this.datosTapa.idCliente = this.cliente.Id
        this.datosTapa.razonSocialCliente = this.cliente.RazonSocial
        this.clienteCtaCte = this.cliente
      } else {
        this.datosTapa.idCliente = null
        this.datosTapa.razonSocialCliente = null
        this.clienteCtaCte = {}
      }
    },

    resetForm () {
      this.cliente = null
      this.clientes = []
      this.clientesQuery = ''
      this.datosTapa = {
        fecha: null,
        idCliente: null,
        razonSocialCliente: null,
        descuento: 0,
        importeTotal: 0,
        comentario: null,
      }
      this.items = []
      this.itemIndex = -1
      this.itemCounter = 0

      this.$refs.obs.reset()
    },

    openNewItem () {
      this.itemIndex = -1
      this.item = {...this.defaultItem}
      this.itemFormDialog = true
    },

    openEditItem (item) {
      this.itemIndex = this.items.findIndex((elem) => {
        return (item.item === elem.item)
      })
      this.item = item
      this.itemFormDialog = true
    },

    removeItem (item) {
      var itemIndex = this.items.findIndex((elem) => {
        return (item.item === elem.item)
      })

      this.items.splice(itemIndex, 1)

      this.updateTotals()
      this.closeItemForm()
    },

    resetItemForm () {
      this.item = {...this.defaultItem}
      this.$refs.item.reset()
    },

    closeItemForm () {
      this.resetItemForm()
      this.itemIndex = -1
      this.itemFormDialog = false
    },

    updateTotals () {
      var descuento = 0.00
      var importeTotal = 0.00

      for (var i = this.items.length - 1; i >= 0; i--) {
        const item = this.items[i]

        descuento+= (item.cantidad * item.precio) - item.subtotal
        importeTotal+= item.subtotal
      }

      this.datosTapa.descuento = descuento
      this.datosTapa.importeTotal = importeTotal
    },

    saveItem () {
      if (this.itemIndex > -1) {
        this.editItem()
      } else {
        this.addItem()
      }
    },

    addItem () {
      const cantidad = parseInt(this.item.cantidad)
      let subtotal = cantidad * parseFloat(this.item.precio)

      if (this.item.bonificacion) {
        const porcBonificacion = (parseFloat(this.item.bonificacion) / 100)

        subtotal = subtotal * ( 1 - porcBonificacion)
      }

      this.itemCounter+= 1

      this.items.push({
        item: this.itemCounter,
        codigo: this.item.codigo,
        detalle: this.item.detalle,
        cantidad,
        precio: this.item.precio,
        bonificacion: this.item.bonificacion,
        subtotal
      })

      // TODO: Cerrar o continuar agregando?
      this.updateTotals()
      this.closeItemForm()
    },

    editItem () {
      const cantidad = parseInt(this.item.cantidad)
      let subtotal = cantidad * parseFloat(this.item.precio)

      if (this.item.bonificacion) {
        const porcBonificacion = (parseFloat(this.item.bonificacion) / 100)

        subtotal = subtotal * ( 1 - porcBonificacion)
      }

      this.$set(this.items[this.itemIndex], 'codigo', this.item.codigo)
      this.$set(this.items[this.itemIndex], 'detalle', this.item.detalle)
      this.$set(this.items[this.itemIndex], 'cantidad', cantidad)
      this.$set(this.items[this.itemIndex], 'precio', this.item.precio)
      this.$set(this.items[this.itemIndex], 'bonificacion', this.item.bonificacion)
      this.$set(this.items[this.itemIndex], 'subtotal', subtotal)

      this.updateTotals()
      this.closeItemForm()
    },

    searchArticulo() {
      this.searchingArticulo = true
      this.filtroArticulo.consulta = this.item.codigo

      this.$soapClient.call('listarArticulos', {
        filtro: this.filtroArticulo,
      })
      .then((response) => {
        if (response.data.filtros.totalRegistros === 1) {
          const articulo = response.data.resultado[0]

          this.item.detalle = articulo.Detalle
          this.item.precio = articulo.lista_precio_principal
        }
      })
      .catch((error) => {
        if (!!error.code && (error.code === "400")) {
          this.$eventHub.$emit('snackbar-message', error.message, 'error')
        } else {
          this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud', 'error')
        }
      })
      .then(() => {
        this.searchingArticulo = false
      })
    },

    addComment () {
      this.commentDialog = true
    },

    closeCommentDialog () {
      this.commentDialog = false
    },

    numberFormat(value, options) {
      const numberFormat = new Intl.NumberFormat('es-AR', options)

      return numberFormat.format(value)
    },

    checkSaldoPendiente () {
      this.$soapClient.call('getCtasCtes', {
        inNroCuenta: this.clienteCtaCte.NroCuenta,
        inCantDias: null,
        inCantMovimientos: 0,
      })
      .then((response) => {
        const result = response.data.resultado
        let saldo = 0

        if (result.saldo) {
          saldo = parseFloat(result.saldo)
        }

        this.clienteSaldo = saldo

        if (saldo > 0) {
          this.saldoPendienteDialog = true
        }
      })
      .catch((error) => {
        if (!!error.code && (error.code === "400")) {
          this.$eventHub.$emit('snackbar-message', error.message, 'error')
        } else {
          this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud', 'error')
        }
      })
    },
  }
}

</script>

<style scoped>
  >>> .v-data-table-header-mobile {
    display: none;
  }

  >>> .v-data-table--mobile.items-data-table table > tbody > tr > td:nth-child(1) > div.v-data-table__mobile-row__header {
    display: none;
  }

  >>> .v-data-table--mobile.items-data-table table > tbody > tr > td:nth-child(1) > div.v-data-table__mobile-row__cell {
    width: 100%;
  }
</style>
