<template>
  <v-container class="align-self-start">
    <ValidationObserver v-if="!showListadoArticulos" ref="obs" v-slot="{ invalid, validated, passes }"
      class="align-self-start col-12 pa-0">
      <v-form @submit.prevent="passes(save)">
        <v-container
          :style="{ 'padding-bottom': ($vuetify.breakpoint.smAndUp ? '60px' : '100px') }"
        >
          <v-alert
            v-if="existDraft"
            prominent
            type="info"
          >
            <v-row align="center">
              <v-col class="grow">
                {{ draftMessage }}
              </v-col>
              <v-col class="shrink">
                <v-btn  @click="LoadDraft">Cargar Borrador</v-btn>
              </v-col>
            </v-row>
          </v-alert>

          <ValidationProvider name="Cliente" rules="required" v-slot="{ errors, valid }">
            <v-autocomplete
              ref="cliente"
              filled
              v-model="cliente"
              :loading="loadingClientes"
              :items="clientes"
              :search-input.sync="clientesQuery"
              cache-items
              clearable
              required
              label="Cliente"
              placeholder="Buscar cliente"
              item-text="RazonSocial"
              item-value="Id"
              return-object
              :error-messages="errors"
              :success="valid"
              @input="setCliente"
              :no-data-text="noDataText"
            ></v-autocomplete>
          </ValidationProvider>

          <ValidationProvider name="Items" rules="required" v-slot="{ errors, valid }">
            <v-data-table
              v-model="itemsModel"
              :headers="headers"
              :items="items"
              class="elevation-1 align-self-start col-12 pa-0 mb-4 items-data-table"
              item-key="id"
              disable-pagination
              hide-default-footer
            >
              <template v-slot:top>
                <v-toolbar flat color="white">
                  <v-toolbar-title>Items</v-toolbar-title>

                  <v-divider
                    class="mx-4"
                    inset
                    vertical
                  ></v-divider>

                  <v-spacer></v-spacer>

                  <v-btn :disabled="cliente === null" small color="primary" @click="openNewItem()">Nuevo</v-btn>
                </v-toolbar>
              </template>
              <template v-slot:item.precio="{ item }">
                <span class="text-no-wrap">
                  {{ numberFormat(item.precio, moneyFormatOptions) }}
                </span>
              </template>
              <template v-slot:item.subtotal="{ item }">
                <span class="text-no-wrap">
                  {{ numberFormat(item.subtotal, moneyFormatOptions) }}
                </span>
              </template>
              <template v-slot:item.bonificacion="{ item }">
                <span class="text-no-wrap">{{ item.bonificacion }} %</span>
              </template>
              <template v-slot:item.action="{ item }">
                <template v-if="$vuetify.breakpoint.xs">
                  <v-container fluid class="pa-0">
                    <v-row no-gutters>
                      <v-col cols="6" class="text-left font-weight-bold">
                        Cod. {{ item.codigo }}
                      </v-col>
                      <v-col cols="6">
                        <span class="text-no-wrap">
                          <v-icon
                            color="primary"
                            class="ml-2"
                            @click="openEditItem(item)"
                            aria-label="Editar"
                            title="Editar"
                          >
                            mdi-pencil
                          </v-icon>

                          <v-icon
                            color="error"
                            class="ml-2"
                            @click="removeItem(item)"
                            aria-label="Quitar"
                            title="Quitar"
                          >
                            mdi-delete
                          </v-icon>
                        </span>
                      </v-col>
                    </v-row>
                  </v-container>
                </template>
                <template v-else>
                  <span class="text-no-wrap">
                    <v-icon
                      color="primary"
                      class="ml-2"
                      @click="openEditItem(item)"
                      aria-label="Editar"
                      title="Editar"
                    >
                      mdi-pencil
                    </v-icon>

                    <v-icon
                      color="error"
                      class="ml-2"
                      @click="removeItem(item)"
                      aria-label="Quitar"
                      title="Quitar"
                    >
                      mdi-delete
                    </v-icon>
                  </span>
                </template>
              </template>
              <template v-slot:item.stock="{ item }">
                <span class="text-no-wrap">
                  <v-chip :color="item.stock > 100 ? 'green' : item.stock > 50  ? 'yellow' : 'red'" text-color="white" small>
                    {{ item.stock }}
                  </v-chip>
                </span>
              </template>
              <template v-slot:item.cantidad="{ item }">
                <template v-if="$vuetify.breakpoint.xs">
                  <span class="text-no-wrap">
                    {{ item.cantidad }}

                    <v-chip class="ml-2" :color="item.stock > 100 ? 'green' : item.stock > 50  ? 'yellow' : 'red'" text-color="white" small>
                      Stock {{ item.stock }}
                    </v-chip>
                  </span>
                </template>
                <template v-else>
                  <span class="text-no-wrap">{{ item.cantidad }}</span>
                </template>
              </template>
            </v-data-table>

            <template v-if="!valid">
              <template v-for="(error, key) in errors">
                <div :key="key" class="caption error--text my-2">{{ error }}</div>
              </template>
            </template>
          </ValidationProvider>

          <ValidationProvider
            v-if="$vuetify.breakpoint.smAndUp"
            name="Comentario"
            rules=""
            v-slot="{ errors, valid }"
          >
            <v-textarea
              v-model="datosTapa.comentario"
              label="Comentario"
              :error-messages="errors"
              :success="valid"
            ></v-textarea>
          </ValidationProvider>

          <v-footer
            fixed
            style="bottom: 33px;"
            class="white"
            padless
          >
            <v-card
              flat
              tile
              width="100%"
              class="transparent"
            >
              <v-divider></v-divider>

              <v-row
                class="py-4 px-2 px-sm-4"
                :class="{ 'text-right': $vuetify.breakpoint.smAndUp }"
                :justify="$vuetify.breakpoint.smAndUp ? 'end' : 'space-between'"
                :align="$vuetify.breakpoint.smAndUp ? 'center' : 'center'"
                no-gutters
              >
                <v-col
                  cols="6" sm="auto"
                  class="px-2"
                  :class="{ 'visibility-hidden': !discountIsVisible }"
                  title="Descuento"
                >
                  <span class="caption">{{ $vuetify.breakpoint.smAndUp ? 'Descuento' : 'Desc' }}</span>: <span class="text-no-wrap text-monospace orange--text">{{ numberFormat(datosTapa.descuento, moneyFormatOptions) }}</span>
                </v-col>

                <v-col
                  cols="6" sm="auto"
                  class="px-2"
                  :class="{ 'text-right': $vuetify.breakpoint.xs }"
                  title="Importe Total"
                >
                  <span class="caption">{{ $vuetify.breakpoint.smAndUp ? 'Total:' : '' }}</span> <span class="text-no-wrap text-monospace green--text font-weight-bold">{{ numberFormat(datosTapa.importeTotal, moneyFormatOptions) }}</span>
                </v-col>

                <v-col
                  v-if="$vuetify.breakpoint.xsOnly"
                  cols="6" sm="auto"
                  class="px-2"
                >
                  <v-btn
                    color="secondary"
                    block
                    dark
                    @click="addComment"
                    title="Agregar Comentario"
                  >
                    Comentario
                  </v-btn>
                </v-col>

                <v-col cols="6" sm="auto" class="px-2">
                  <v-btn
                    type="submit"
                    color="primary"
                    :block="$vuetify.breakpoint.xsOnly"
                    dark
                    :loading="saving"
                    @click.prevent="passes(save)"
                  >
                    Guardar
                  </v-btn>
                </v-col>
              </v-row>

              <v-divider></v-divider>
            </v-card>
          </v-footer>
        </v-container>
      </v-form>
    </ValidationObserver>

    <template v-else>
      <v-data-table
        :headers="$vuetify.breakpoint.xs ? headersXsArticulos : headersArticulos"
        :items="itemsArticulos"
        :server-items-length="totalArticulos"
        :options.sync="optionsArticulos"
        :loading="loadingArticulos"
        class="elevation-1 align-self-start col-12 pa-0"
        item-key="index"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>Productos</v-toolbar-title>

            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>

            <v-spacer></v-spacer>

            <v-text-field
              v-model="searchArticulos"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
            ></v-text-field>
          </v-toolbar>
        </template>

        <template v-slot:item.Precio="{ item }">
            $ {{ item.lista_precio_principal }}
        </template>

        <template v-slot:item.action="{ item }">
          <v-btn
            icon
            color="info"
            class="ml-4"
            @click="openArticulo(item)"
            aria-label="Agregar al pedido"
            title="Agregar al pedido"
            :disabled="loadingStock"
            :loading="loadingStock"
          >
            <v-icon>mdi-cart-plus</v-icon>
          </v-btn>
        </template>
      </v-data-table>

      <v-card flat>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text @click="showListadoArticulos = false">
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>

    <v-dialog v-model="itemFormDialog" max-width="500px">
      <ValidationObserver ref="item" v-slot="{ passes }">
        <v-form @submit.prevent="passes(saveItem)">
          <v-card>
            <v-card-title>
              <span class="headline">
                {{ formTitle }}
              </span>
            </v-card-title>

            <v-divider></v-divider>

            <v-card-text>
              <ValidationProvider
                name="Código"
                rules="required"
                v-slot="{ errors, valid }"
                class="mb-4"
              >
                <v-text-field
                  v-model="item.codigo"
                  label="Código"
                  :error-messages="errors"
                  :success="valid"
                  required
                  readonly
                ></v-text-field>
              </ValidationProvider>

              <ValidationProvider
                name="Detalle"
                rules="required"
                v-slot="{ errors, valid }"
                class="mb-4"
              >
                <v-text-field
                  v-model="item.detalle"
                  label="Detalle"
                  :error-messages="errors"
                  :success="valid"
                  required
                  readonly
                ></v-text-field>
              </ValidationProvider>

              <ValidationProvider
                name="Cantidad"
                rules="required|min_value:1|integer"
                v-slot="{ errors, valid }"
                class="mb-4"
              >
                <v-text-field
                  filled
                  type="number"
                  step="1"
                  min="1"
                  v-model.number="item.cantidad"
                  label="Cantidad"
                  :error-messages="errors"
                  :success="valid"
                  required
                  @change="checkStock"
                ></v-text-field>
              </ValidationProvider>

              <ValidationProvider
                name="Precio"
                rules="required|min_value:0.01"
                v-slot="{ errors, valid }"
                class="mb-4"
              >
                <v-text-field
                  type="number"
                  step="0.01"
                  min="0.01"
                  v-model.number="item.precio"
                  label="Precio"
                  :error-messages="errors"
                  :success="valid"
                  required
                  readonly
                ></v-text-field>
              </ValidationProvider>

              <ValidationProvider
                name="Bonificación"
                rules="required|min_value:0|integer"
                v-slot="{ errors, valid }"
              >
                <v-text-field
                  filled
                  type="number"
                  step="0.01"
                  v-model.number="item.bonificacion"
                  label="Bonificación"
                  append-icon="mdi-percent"
                  :error-messages="errors"
                  :success="valid"
                ></v-text-field>
              </ValidationProvider>
            </v-card-text>

            <v-alert
              v-if="stockAlert"
              dense
              type="warning"
              class="rounded-0 mb-0"
            >
              No se dispone del stock necesario. Existencia actual: {{ item.stock }} unidades, consultar con quien corresponda.
            </v-alert>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                type="submit"
                color="primary"
                text
              >
                Guardar
              </v-btn>

              <v-btn text @click="closeItemForm">Cerrar</v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </ValidationObserver>
    </v-dialog>

    <v-dialog v-model="commentDialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">
            Agregar Comentario
          </span>
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text>
          <v-textarea
            v-model="datosTapa.comentario"
            label="Comentario"
          ></v-textarea>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text @click="closeCommentDialog">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="saldoPendienteDialog" max-width="600">
      <v-card>
        <v-toolbar color="warning" dark flat>
          AVISO
        </v-toolbar>

        <v-card-text class="text-h6 pa-12">
          El cliente {{ clienteCtaCte.RazonSocial }} tiene un saldo pendiente de {{ numberFormat(clienteSaldo, moneyFormatOptions) }}.
        </v-card-text>

        <v-card-actions class="justify-end">
          <v-btn
            color="primary"
            text
            @click="dialogCtaCte = true"
          >
            VER CTA CTE
          </v-btn>

          <v-btn text @click="saldoPendienteDialog = false">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogCtaCte" scrollable fullscreen>
      <CtaCteList
        :active="dialogCtaCte"
        :cliente="clienteCtaCte"
        @dismiss="dialogCtaCte = false"
      />
    </v-dialog>

    <v-dialog v-model="dialogChangePriceList" scrollable persistent>
      <v-card>
        <v-toolbar color="warning" dark flat>
          AVISO
        </v-toolbar>

        <v-card-text class="text-h6 pa-12">
          El Cliente seleccionado tiene asociada otra lista de precios, se eliminarán los artículos cargados en el pedido. ¿Desea continuar?
        </v-card-text>

        <v-card-actions class="justify-end">
          <v-btn
            color="primary"
            text
            @click="changeClientWithDifferentList()"
          >
            Continuar
          </v-btn>

          <v-btn text @click="cancelChangeClientDialog()">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>

import _ from 'lodash'
import {
  ValidationObserver,
  ValidationProvider
} from "vee-validate";
import CtaCteList from '../../components/CtaCteList'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    CtaCteList,
  },

  data: () => ({
    loading: false,
    loadingArticulos: false,
    saving: false,
    loadingClientes: false,
    clientes: [],
    cliente: null,
    clientePrev: {},
    clientesQuery: '',
    clienteSaldo: 0,
    clienteCtaCte: {},
    datosTapa: {
      fecha: null,
      idCliente: null,
      razonSocialCliente: null,
      descuento: 0,
      importeTotal: 0,
      comentario: null,
    },
    itemsModel: [],
    items: [],
    itemIndex: -1,
    itemCounter: 0,
    item: {
      item: null,
      codigo: '',
      detalle: '',
      cantidad: 1,
      precio: 0,
      bonificacion: 0,
      subtotal: 0,
      stock: 0
    },
    defaultItem: {
      item: null,
      codigo: '',
      detalle: '',
      cantidad: 1,
      precio: 0,
      bonificacion: 0,
      subtotal: 0,
      stock: 0
    },
    itemFormDialog: false,
    commentDialog: false,
    saldoPendienteDialog: false,
    dialogChangePriceList: false,
    dialogCtaCte: false,
    stockAlert: false,
    loadingStock: false,
    showListadoArticulos: false,
    searchArticulos: '',
    headersXsArticulos: [
      { text: 'Detalle', value: 'Detalle', sortable: false },
      { text: 'Precio', value: 'Precio', sortable: false },
      { text: '', value: 'action', align: 'right', sortable: false },
    ],
    headersArticulos: [
      { text: 'ID', value: 'id', align: 'left', sortable: false },
      { text: 'Código', value: 'Codigo', sortable: false },
      { text: 'Detalle', value: 'Detalle', sortable: false },
      { text: 'Precio', value: 'Precio', sortable: false },
      { text: 'Acciones', value: 'action', align: 'right', sortable: false },
    ],
    itemsArticulos: [],
    totalArticulos: 0,
    optionsArticulos: {},
    filtroArticulo: {
      consulta: null,
      pagina_solicitada: 1,
      registrosPorPagina: 1,
    },
    moneyFormatOptions: {
      style: 'currency',
      currency: 'ARS',
    },
    existDraft: false,
    draftMessage: '',
    noDataText: 'Escriba para buscar...',
  }),

  computed: {
    formTitle: {
      get() {
        return (this.itemIndex > -1) ? 'Editar item' : 'Nuevo item'
      },
    },

    discountIsVisible: {
      get() {
        return this.datosTapa.descuento > 0
      }
    },

    headers: {
      get() {
        let headers = []

        if (this.$vuetify.breakpoint.xs) {
          headers = [
            { text: '', value: 'action', sortable: false },
            { text: 'Detalle', value: 'detalle', sortable: false },
            { text: 'Cantidad', value: 'cantidad', align: 'right', sortable: false },
            { text: 'Precio', value: 'precio', align: 'right', sortable: false },
            { text: 'Bonificación', value: 'bonificacion', align: 'right', sortable: false },
            { text: 'Subtotal', value: 'subtotal', align: 'right', sortable: false},
          ]
        } else {
          headers = [
            { text: 'Código', value: 'codigo', sortable: false },
            { text: 'Detalle', value: 'detalle', sortable: false },
            { text: 'Cantidad', value: 'cantidad', align: 'right', sortable: false },
            { text: 'Precio', value: 'precio', align: 'right', sortable: false },
            { text: 'Bonificación', value: 'bonificacion', align: 'right', sortable: false},
            { text: 'Subtotal', value: 'subtotal', align: 'right', sortable: false },
            { text: 'Stock', value: 'stock', align: 'center', sortable: false },
            { text: 'Acciones', value: 'action', align: 'right', sortable: false },
          ]
        }

        if (!this.discountIsVisible) {
          const bonIndex = headers.findIndex(item => item.value == 'bonificacion')

          headers.splice(bonIndex, 1)
        }

        return headers
      }
    },
  },

  watch: {
    optionsArticulos: {
      handler () {
        this.queryArticulos()
      },
      deep: true,
    },

    searchArticulos: function (/*val*/) {
      this.debounceRefresh()
    },

    clientesQuery (val) {
      if (!this.cliente && val) {
        this.noDataText = 'Buscando...'
        this.debounceClientes(val)
      }
    },
  },

  mounted() {
    if (this.$route.query.cliente) {
      this.loadingClientes = true;

      this.$soapClient.call('getCliente', {
        id: this.$route.query.cliente
      })
      .then((response) => {
        this.clientes.push(response.data.resultado);
        this.cliente = response.data.resultado;

        this.setCliente()
      })
      .catch((error) => {
        if (!!error.code && (error.code === "400")) {
          this.$eventHub.$emit('snackbar-message', error.message, 'error')
        } else {
          this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud', 'error')
        }
      })
      .then(() => {
        this.loadingClientes = false;
      })
    }
  },

  beforeDestroy() {
    if (this.items.length || this.cliente || this.datosTapa.comentario) {
      const date = new Date();
      const data = {
        items: this.items,
        cliente: this.cliente,
        datosTapa: this.datosTapa,
        createdDate: date.toLocaleDateString(undefined, { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' })
      };

      sessionStorage.setItem('borradorCrearPedido', JSON.stringify(data));
    }
  },

  created() {
    if (sessionStorage.getItem('borradorCrearPedido')) {
      const borradorCrearPedido = JSON.parse(sessionStorage.getItem('borradorCrearPedido'));

      this.existDraft = true
      this.draftMessage = 'Tiene un borrador del '+ borradorCrearPedido.createdDate
    }
  },

  methods: {
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')

      return `${day}/${month}/${year}`
    },

    debounceClientes: _.debounce(function (val) {
      this.queryClientes(val)
    }, 1000),

    debounceRefresh: _.debounce(function () {
      this.refresh()
    }, 1000),

    refresh: function () {
      this.optionsArticulos.page = 1
      this.queryArticulos()
    },

    queryClientes (val) {
      this.loadingClientes = true;

      this.$soapClient.call('listarClientes', {
        filtro: {
          consulta: val,
          // pagina_solicitada: 1
          // registrosPorPagina: 100
        },
      })
      .then((response) => {
        this.clientes = response.data.resultado;
        
        if (!this.clientes.length) {
          this.noDataText = 'No hay datos disponibles'
        }
      })
      .catch((error) => {
        if (!!error.code && (error.code === "400")) {
          this.$eventHub.$emit('snackbar-message', error.message, 'error')
        } else {
          this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud', 'error')
        }
      })
      .then(() => {
        this.loadingClientes = false;
      })
    },

    save () {
      this.saving = true

      this.datosTapa['_xsi:type'] = 'ns2:TapaPedido'
      this.datosTapa.fecha = (new Date()).toISOString().substr(0, 10)

      var items = []
      var itemsAux = {}

      var itemsCount = this.items.length

      for (var i = 0; i < itemsCount; i++) {
        var item = this.items[i]

        itemsAux['item'+(i + 1)] = {
          '_xsi:type': 'ns2:CuerpoPedido',
          item: item.item,
          codigo: item.codigo,
          detalle: item.detalle,
          cantidad: item.cantidad,
          precio: item.precio,
          bonificacion: item.bonificacion,
          subtotal: item.subtotal,
        }
      }

      items.push(itemsAux)

      this.$soapClient.call('crearPedido', {
        datosTapa: this.datosTapa,
        items: items,
      })
      .then(() => {
        this.checkSaldoPendiente(this.cliente)
        this.resetForm()

        this.$eventHub.$emit('snackbar-message', 'Los datos fueron guardados con éxito', 'success')
      })
      .catch((error) => {
        if (!!error.code && (error.code === "400")) {
          this.$eventHub.$emit('snackbar-message', error.message, 'error')
        } else {
          this.$eventHub.$emit('snackbar-message', 'Disculpe, no se pudo completar su solicitud', 'error')
        }
      })
      .then(() => {
        this.saving = false
      })
    },

    setCliente () {
      this.clienteSaldo = 0

      if (this.cliente && this.cliente?.Id) {
        // Controlar si cambio la lista de precios
        if (this.items.length && (this.cliente.idListaPrecio !== this.clientePrev.idListaPrecio)) {
          this.dialogChangePriceList = true
          return
        }

        this.setClienteData()
      } else {
        this.datosTapa.idCliente = null
        this.datosTapa.razonSocialCliente = null
        this.clienteCtaCte = {}
      }
    },

    setClienteData () {
        this.datosTapa.idCliente = this.cliente.Id
        this.datosTapa.razonSocialCliente = this.cliente.RazonSocial
        this.clienteCtaCte = this.cliente // TODO: Revisar porque usa 2 variables para mismos datos
        this.clientePrev = {...this.cliente}
    },

    changeClientWithDifferentList () {
      this.setClienteData()
      this.items = []
      this.dialogChangePriceList = false
    },

    cancelChangeClientDialog() {
      this.cliente = {...this.clientePrev}
      this.dialogChangePriceList = false
    },

    resetForm () {
      this.cliente = null
      this.clientePrev = {}
      this.clientes = []
      this.clientesQuery = ''
      this.datosTapa = {
        fecha: null,
        idCliente: null,
        razonSocialCliente: null,
        descuento: 0,
        importeTotal: 0,
        comentario: null,
      }
      this.itemsModel = []
      this.items = []
      this.itemIndex = -1
      this.itemCounter = 0
      this.existDraft = false

      sessionStorage.removeItem('borradorCrearPedido');

      this.$refs.obs.reset()
    },

    openNewItem () {
      this.itemIndex = -1
      this.item = {...this.defaultItem}
      this.showListadoArticulos = true
    },

    openEditItem (item) {
      this.itemIndex = this.items.findIndex((elem) => {
        return (item.item === elem.item)
      })
      this.item = item
      this.itemFormDialog = true
    },

    removeItem (item) {
      var itemIndex = this.items.findIndex((elem) => {
        return (item.item === elem.item)
      })

      this.items.splice(itemIndex, 1)
      this.itemsModel = this.items

      this.updateTotals()
      this.closeItemForm()
    },

    resetItemForm () {
      this.item = {...this.defaultItem}

      if (this.$refs.item) {
        this.$refs.item.reset()
      }
    },

    closeItemForm () {
      this.resetItemForm()
      this.itemIndex = -1
      this.itemFormDialog = false
    },

    updateTotals () {
      var descuento = 0.00
      var importeTotal = 0.00

      for (var i = this.items.length - 1; i >= 0; i--) {
        const item = this.items[i]

        descuento+= (item.cantidad * item.precio) - item.subtotal
        importeTotal+= item.subtotal
      }

      this.datosTapa.descuento = descuento
      this.datosTapa.importeTotal = importeTotal
    },

    saveItem () {
      if (this.itemIndex > -1) {
        this.editItem()
      } else {
        this.addItem()
      }
    },

    addItem () {
      const cantidad = parseInt(this.item.cantidad)
      let subtotal = cantidad * parseFloat(this.item.precio)

      if (this.item.bonificacion) {
        const porcBonificacion = (parseFloat(this.item.bonificacion) / 100)

        subtotal = subtotal * ( 1 - porcBonificacion)
      }

      this.itemCounter+= 1

      this.items.push({
        item: this.itemCounter,
        codigo: this.item.codigo,
        detalle: this.item.detalle,
        cantidad,
        precio: this.item.precio,
        bonificacion: this.item.bonificacion,
        subtotal,
        stock: this.item.stock,
      })

      this.itemsModel = this.items

      // TODO: Cerrar o continuar agregando?
      this.updateTotals()
      this.closeItemForm()
    },

    editItem () {
      const cantidad = parseInt(this.item.cantidad)
      let subtotal = cantidad * parseFloat(this.item.precio)

      if (this.item.bonificacion) {
        const porcBonificacion = (parseFloat(this.item.bonificacion) / 100)

        subtotal = subtotal * ( 1 - porcBonificacion)
      }

      this.$set(this.items[this.itemIndex], 'codigo', this.item.codigo)
      this.$set(this.items[this.itemIndex], 'detalle', this.item.detalle)
      this.$set(this.items[this.itemIndex], 'cantidad', cantidad)
      this.$set(this.items[this.itemIndex], 'precio', this.item.precio)
      this.$set(this.items[this.itemIndex], 'bonificacion', this.item.bonificacion)
      this.$set(this.items[this.itemIndex], 'subtotal', subtotal)
      this.$set(this.items[this.itemIndex], 'stock', this.item.stock)

      this.itemsModel = this.items

      this.updateTotals()
      this.closeItemForm()
    },

    addComment () {
      this.commentDialog = true
    },

    closeCommentDialog () {
      this.commentDialog = false
    },

    numberFormat(value, options) {
      const numberFormat = new Intl.NumberFormat('es-AR', options)

      return numberFormat.format(value)
    },

    queryArticulos: function () {
      this.loadingArticulos = true
      this.itemsArticulos = []
      this.totalArticulos = 0

      const params = {}

      this.filtroArticulo.consulta = this.searchArticulos
      this.filtroArticulo.pagina_solicitada = this.optionsArticulos.page
      this.filtroArticulo.registrosPorPagina = this.optionsArticulos.itemsPerPage

      params.filtro = this.filtroArticulo

      if (this.cliente.idListaPrecio) {
        params.idListaPrecio = this.cliente.idListaPrecio
      }

      this.$soapClient.call('listarArticulos', params)
      .then((response) => {
        this.itemsArticulos = response.data.resultado
        this.totalArticulos = response.data.filtros.totalRegistros
      })
      .catch((error) => {
        if (!!error.code && (error.code === "400")) {
          this.$eventHub.$emit('snackbar-message', error.message, 'error')
        } else {
          this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud', 'error')
        }
      })
      .then(() => {
        this.loadingArticulos = false
      })
    },

    openArticulo (item) {
      this.getStock(item).then((response) => {
        this.item = {
          item: null,
          codigo: item.Codigo,
          detalle: item.Detalle,
          cantidad: 1,
          precio: item.lista_precio_principal,
          bonificacion: 0,
          subtotal: 0,
          stock: response.data.resultado.Stock,
        }
        this.showListadoArticulos = false
        this.itemFormDialog = true
        this.checkStock()
      })
    },

    getStock (item) {
      this.loadingStock = true

      return new Promise((resolve, reject) => {
        this.$soapClient.call('getArticulo', {
          id: item.id,
        })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          if (!!error.code && (error.code === "400")) {
            this.$eventHub.$emit('snackbar-message', error.message, 'error')
          } else {
            this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud', 'error')
          }

          reject(error)
        })
        .then(() => {
          this.loadingStock = false
        })
      })
    },

    checkSaldoPendiente () {
      this.$soapClient.call('getCtasCtes', {
        inNroCuenta: this.clienteCtaCte.NroCuenta,
        inCantDias: null,
        inCantMovimientos: 0,
      })
      .then((response) => {
        const result = response.data.resultado
        let saldo = 0

        if (result.saldo) {
          saldo = parseFloat(result.saldo)
        }

        this.clienteSaldo = saldo

        if (saldo > 0) {
          this.saldoPendienteDialog = true
        }
      })
      .catch((error) => {
        if (!!error.code && (error.code === "400")) {
          this.$eventHub.$emit('snackbar-message', error.message, 'error')
        } else {
          this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud', 'error')
        }
      })
    },

    checkStock () {
      this.stockAlert = ((this.item.stock - this.item.cantidad) < 0)
    },

    LoadDraft() {
      const borradorCrearPedido = JSON.parse(sessionStorage.getItem('borradorCrearPedido'));

      this.itemsModel = borradorCrearPedido.items
      this.items = borradorCrearPedido.items
      this.itemCounter = borradorCrearPedido.items.length
      this.cliente = borradorCrearPedido.cliente;
      this.datosTapa = borradorCrearPedido.datosTapa;
      this.existDraft = false;

      if (this.cliente) {
        this.queryClientes(this.cliente.RazonSocial)
      }

      sessionStorage.removeItem('borradorCrearPedido')
    }
  },
}

</script>

<style scoped>
  >>> .v-data-table-header-mobile {
    display: none;
  }

  >>> .v-data-table--mobile.items-data-table table > tbody > tr > td:nth-child(1) > div.v-data-table__mobile-row__header {
    display: none;
  }

  >>> .v-data-table--mobile.items-data-table table > tbody > tr > td:nth-child(1) > div.v-data-table__mobile-row__cell {
    width: 100%;
  }

  >>> .visibility-hidden {
    visibility: hidden;
  }
</style>
